/**
 *
 * @param errors
 * @constructor
 */
export function ValidationError(errors) {
  Error.call(this, errors);
  this.name = "ValidationError";

  this.errors = errors;
  this.message = "Error validation";
  this.status = 422;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ValidationError);
  } else {
    this.stack = new Error().stack;
  }
}

/**
 *
 * @type {Error}
 */
ValidationError.prototype = Object.create(Error.prototype);


/**
 *
 * @param response
 * @returns {*}
 */
export function checkHttpStatus(response) {
  if (response.status.error === 0) {
    return response;
  } else {
    if (response.status.message === "нет такой сессии") {
      localStorage.removeItem("sessionId");
      localStorage.removeItem("userId");
      console.log(response.status.message);
    } else {
      if (response.status.message === "нет такого") {
        localStorage.setItem("sessionId", "0");
        localStorage.removeItem("userId");
        console.log(response.status.message);
      }
    }

    if (response.status === 422) {
      throw new ValidationError(response.data);
    }

    throw new Error(response.status.message);
  }
}

/**
 *
 * @param response
 * @returns {*}
 */
export function parseJSON(response) {
  return response.json();
}
