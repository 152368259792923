import fetchData from "./fetchData";
import { parseJSON, checkHttpStatus } from "../shared/utils";
import config from "../config/index";

async function setTry(payload) {
  return fetchData(
    `${config.api.baseUrl}?class=seller&action=setredirect`,
    "POST",
    payload
  )
    .then(parseJSON)
    .then(checkHttpStatus);
}

export {
  setTry
};
