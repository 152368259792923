import MobileDetect from 'mobile-detect';
import { useEffect, useState } from 'react';
import './App.css';
import { androidLink, iosLink, policyHTML } from './constants'
import { setTry } from './api/common';

function App() {

  const [isPolicy, setIsPolicy] = useState(false);

  useEffect(() => {
    setIsPolicy(window.location.pathname === '/policy')
    if (window.location.pathname !== '/policy') {
      const typeOs = new MobileDetect(window.navigator.userAgent);
      // const queryParams = new URLSearchParams(window.location.search);
      // const androidUrl = queryParams.get('androidUrl');
      // const iosUrl = queryParams.get('iosUrl');

      // (typeOs.os() === "AndroidOS" && androidUrl?.length > 0) ? window.location.replace(androidUrl)
      // : (typeOs.os() === "iOS" && iosUrl?.length > 0) ? window.location.replace(iosUrl)
      // : console.log("Undefined OS type")
      const queryParams = new URLSearchParams(window.location.search);
      const seller = queryParams.get('seller');
      const pointRef = queryParams.get('pointRef');

      const strPointRef = (pointRef === null) ? "" : `&referrer={point:${pointRef}}`;

      // console.log(pointRef);

      setTry({seller:seller, point: pointRef, os: typeOs.os()}).then((res) => {
        const {result} = res;
        (typeOs.os() === "AndroidOS") ? window.location.replace(result[0].s_redirect_android)
        : (typeOs.os() === "iOS") ? window.location.replace(result[0].s_redirect_ios)
        : console.log("Undefined OS type");
      }).catch((err) => {
        console.log(err);
      });
      
      // (typeOs.os() === "AndroidOS") ? window.location.replace(androidLink.concat(strPointRef))
      // : (typeOs.os() === "iOS") ? window.location.replace(iosLink.concat(strPointRef))
      // : console.log("Undefined OS type");
    }
  },[])
  
  return (
    isPolicy ? 
    <div dangerouslySetInnerHTML={{ __html: policyHTML }}></div> :
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
