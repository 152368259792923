export default function fetchData(url, method = 'GET', body = null) {
  const config = {
    method,
    headers: {},
  };

  if (!body) {
    body = {};
  }

  config.body = Object.entries(body)
    .map(e => e.join('='))
    .join('&');

  return fetch(url, config);
}
